import React, {useState}  from 'react';
import {Row, Col, Form, Button} from 'react-bootstrap';
import Box from 'components/box/box';
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css';
import Title from 'components/title/title';
//import Modal from 'components/modal/modal';
const formStyle = {
  margin: "0 auto",
  display: "block",
  width: "50%",
}

export default function ContactForm() {

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: 'post',
      url: 'https://getform.io/f/aeeb3c98-feae-4836-9b4a-20d8d861778e',
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, 'Thanks!', form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (

  <Box>
    {serverState.status && (
      <Title className={!serverState.status.ok ? 'Oops, something went wrong: ' : ' '}>
        {serverState.status.msg}
      </Title>
    )}
      <Form style={formStyle} onSubmit={handleOnSubmit}>
        <Form.Group as={Row} controlId="formBasicEmail">
          {/*<Form.Label>Email</Form.Label>*/}
          <Form.Control size="lg" name="email" type="email" placeholder="email" />
          {/*<Form.Text className="text-muted">*/}
          {/*  We'll never share your email with anyone else.*/}
          {/*</Form.Text>*/}
        </Form.Group>
        <Form.Group as={Row} controlId="formBasicMessage">
          {/*<Form.Label>Message</Form.Label>*/}
          <Form.Control size="lg" placeholder="message" name="message" as="textarea" rows="5" />
        </Form.Group>
        <Button on size="lg" variant="primary" type="submit" disabled={serverState.submitting}>
          Send
        </Button>
      </Form>
      {/*<Modal open={true} >Content in here</Modal>*/}
    </Box>
  )
}
