import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Title, Copy } from './item.css';
import { Button, Modal } from 'react-bootstrap';

// function
//@todo styling to remove button stuff
// add content for modal to item
// const Item = ({ title, copy, image }) => (
//   <button>
//   <figure>
//     <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
//     <figcaption>
//       <Title>{title}</Title>
//       <Copy>{copy}</Copy>
//     </figcaption>
//   </figure>
//   </button>
// );

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
  modalCopy: PropTypes.string
};

//@todo move to own css and work with styled component
const buttonNoStyle = {
  border: 0,
  background: "none",
  outline: "none"
}

export default function Item({title, copy, image, modalCopy}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

      {/*<button style={buttonNoStyle} onClick={handleShow}>*/}
        <figure>
          <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
          <figcaption>
            <Title>{title}</Title>
            <Copy>{copy}</Copy>
          </figcaption>
        </figure>
      {/*</button>*/}


      {/* add conditional for being wrapper in a button */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><Copy>{modalCopy}</Copy></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/*<Button variant="primary" onClick={handleClose}>*/}
          {/*  Save Changes*/}
          {/*</Button>*/}
        </Modal.Footer>
      </Modal>
    </>
  );
}
